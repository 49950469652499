let origin = window.ORIGIN;
//人员趋势
const getPeopleTrendUrl = `${origin}/gateway/hc-low-carbon/digitalCommunity/getPersonTrend`;
//每日日活
const getDauUrl = `${origin}/gateway/hc-low-carbon/digitalCommunity/getDailyAmount`;
//活跃应用(人数)
const getActPeopleUrl = `${origin}/gateway/hc-low-carbon/digitalCommunity/getActivePopulation`;
//积分产生与核销
const getPointsUrl = `${origin}/gateway/hc-low-carbon/digitalCommunity/getPointData`;
export { getPeopleTrendUrl, getDauUrl, getActPeopleUrl, getPointsUrl };
