import * as echarts from "echarts";

/// 水波图
export function drawLineChart(eleId, xData, seriesData) {
  let option = {
    xAxis: {
      type: "category",
      data: xData,
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
      axisLabel: {
        interval: 0,
      },
    },
    tooltip: {
      trigger: "axis",
      formatter: "{b} : <br/>访问人数: {c}",
    },
    yAxis: {
      type: "value",
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
    },
    grid: { top: "10%", left: "10%", right: "10%", bottom: "20%" },
    series: [
      {
        data: seriesData,
        type: "line",
        smooth: true,
        symbolSize: 0,
        lineStyle: {
          color: "#FE9615",
        },
        areaStyle: {
          color: "rgba(254, 149, 20, 0.3)",
        },
        itemStyle: {
          normal: {
            color: "#FE9615",
          },
        },
      },
    ],
  };
  drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
// 双折线
export function drawDLineChart(eleId, xData, seriesData1, seriesData2) {
  let option = {
    xAxis: {
      type: "category",
      data: xData,
      axisLabel: {
        interval: 0,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
    },
    tooltip: {
      trigger: "axis",
      formatter: "{b0}<br/>产生积分: {c1}<br />核销积分: {c0}",
    },
    grid: { top: "10%", left: "10%", right: "10%", bottom: "20%" },
    series: [
      {
        data: seriesData1,
        type: "line",
        symbolSize: 0,
        lineStyle: {
          type: "dashed",
          color: "#9FCAFF",
        },
      },
      {
        data: seriesData2,
        type: "line",
        symbolSize: 0,
        lineStyle: {
          color: "#FE9615",
        },
      },
    ],
  };
  drawedChart(eleId, option).on("click", function (param) {
    console.log(param, param.data); //这里根据param填写你的跳转逻辑
  });
}
// 绘制图表
function drawedChart(eleId, option) {
  let chart = null;
  if (document.getElementById(eleId)) {
    chart = echarts.init(document.getElementById(eleId));
    chart.setOption(option);
  }
  return chart;
}
